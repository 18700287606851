import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

const getResourcesState = (state) => state?.resources;

export const getRemoteResourceParameters = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.remoteResourceParameters,
);

// these learning topics contain their associated resources
export const getLearningTopics = createSelector(getResourcesState, (resourcesState) => resourcesState.learningTopics);

export const getPaginatedResources = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResources.results,
);

export const getPaginatedResourcesStatus = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResourcesStatus,
);

export const getPaginatedResourcesCountByStatus = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResources.countByStatus,
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// used to present 'getPaginatedEngagementParams' with a cleaned state to compare
const cleanPaginatedResourcesParams = createSelector(getResourcesState, (state) => {
    const cleanedParams = { ...state.paginatedResources.params };
    return cleanedParams;
});
// uses lodash.isequal to compare state of the object
export const getPaginatedResourcesParams = createDeepEqualSelector(
    cleanPaginatedResourcesParams,
    (cleanedState) => cleanedState,
);

export const getTotalResourcesCount = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResources.total,
);

export const getResourceLibraries = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState?.libraries || [],
);
