import WebClient from '../utils/web-client';
import UsersTypes from '../action-types/users';
import { APIError, APISuccess } from './app';
import removeEmptyArrays from '../utils/remove-empty-arrays';

import { getPaginatedUsersParams } from '../selectors/users';

const GetPaginatedUsersBegin = () => ({
    type: UsersTypes.GET_PAGINATED_USERS_BEGIN,
});
const GetPaginatedUsersSuccess = (payload) => ({
    type: UsersTypes.GET_PAGINATED_USERS_SUCCESS,
    payload,
});
const GetPaginatedUsersError = () => ({
    type: UsersTypes.GET_PAGINATED_USERS_ERROR,
});

export const GetPaginatedUsers = () => async (dispatch, getState) => {
    dispatch(GetPaginatedUsersBegin());

    const paginatedParams = getPaginatedUsersParams(getState());
    if (paginatedParams.page !== null) {
        const payload = { ...paginatedParams };
        delete payload.page;
        delete payload.pageSize;
        delete payload.results;
        delete payload.total;
        payload.filter = removeEmptyArrays(payload.filter);

        try {
            const { data } = await WebClient.post(
                `/users/${paginatedParams.page}/${paginatedParams.pageSize}`,
                payload,
            );
            dispatch(GetPaginatedUsersSuccess(data));
        } catch (error) {
            dispatch(APIError());
            dispatch(GetPaginatedUsersError());
        }
    }
};

export const UpdatePaginatedUsersParams = (payload) => ({
    type: UsersTypes.UPDATE_PAGINATED_USERS_PARAMS,
    payload,
});

export const ClearPaginatedUsers = () => ({
    type: UsersTypes.CLEAR_PAGINATED_USERS,
});

const DeleteUserBegin = () => ({
    type: UsersTypes.DELETE_USER_BEGIN,
});
const DeleteUserSuccess = () => ({
    type: UsersTypes.DELETE_USER_SUCCESS,
});
const DeleteUserError = () => ({
    type: UsersTypes.DELETE_USER_ERROR,
});

export const DeleteUser = (id, onSuccess) => async (dispatch) => {
    dispatch(DeleteUserBegin());

    try {
        await WebClient.delete(`/users/${id}`);
        dispatch(DeleteUserSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(DeleteUserError());
    }
};

const AddUserBegin = () => ({
    type: UsersTypes.ADD_USER_BEGIN,
});
const AddUserSuccess = () => ({
    type: UsersTypes.ADD_USER_SUCCESS,
});
const AddUserError = () => ({
    type: UsersTypes.ADD_USER_ERROR,
});

export const AddUser = (data, onSuccess) => async (dispatch) => {
    dispatch(AddUserBegin());
    const payload = { ...data };
    delete payload.type; // data point used in form, not wanted by API

    try {
        const response = await WebClient.post('/users', payload);
        dispatch(AddUserSuccess());
        dispatch(APISuccess('User successfully added.'));
        dispatch(GetPaginatedUsers());
        if (onSuccess) onSuccess(response.data);
    } catch (error) {
        let errorMsg = 'Error adding user.';
        if (error.response && error.response.status === 409) {
            errorMsg = `A user with the email address ${payload.email} already exists. Please enter a new email address.`;
        }
        dispatch(APIError(errorMsg));
        dispatch(AddUserError());
    }
};

const SetCoachesBegin = () => ({
    type: UsersTypes.SET_COACHES_BEGIN,
});
const SetCoachesSuccess = () => ({
    type: UsersTypes.SET_COACHES_SUCCESS,
});
const SetCoachesError = () => ({
    type: UsersTypes.SET_COACHES_ERROR,
});

export const SetCoaches = (userId, coachIds, engagementId, onSuccess) => async (dispatch) => {
    dispatch(SetCoachesBegin());

    try {
        await WebClient.patch(`/users/${userId}/set-coaches`, { coachIds, engagementId });
        dispatch(SetCoachesSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        const errorMsg = 'Error assigning coach.';
        dispatch(APIError(errorMsg));
        dispatch(SetCoachesError());
    }
};

const UpdateCoachBegin = () => ({
    type: UsersTypes.UPDATE_COACH_BEGIN,
});
const UpdateCoachSuccess = () => ({
    type: UsersTypes.UPDATE_COACH_SUCCESS,
});
const UpdateCoachError = () => ({
    type: UsersTypes.UPDATE_COACH_ERROR,
});

export const UpdateCoach = (payload, onSuccess) => async (dispatch) => {
    dispatch(UpdateCoachBegin());

    try {
        const { data } = await WebClient.patch('/user', payload);
        dispatch(UpdateCoachSuccess());
        dispatch(APISuccess('Profile successfully updated.'));

        if (onSuccess) onSuccess(data);
    } catch (error) {
        const errorMsg = 'Error updating profile.';
        dispatch(APIError(errorMsg));
        dispatch(UpdateCoachError());
    }
};

const GetAllCertificationsBegin = () => ({
    type: UsersTypes.GET_CERTIFICATIONS_BEGIN,
});
const GetAllCertificationsSuccess = (payload) => ({
    type: UsersTypes.GET_CERTIFICATIONS_SUCCESS,
    payload,
});
const GetAllCertificationsError = () => ({
    type: UsersTypes.GET_CERTIFICATIONS_ERROR,
});

export const GetAllCertifications = () => async (dispatch) => {
    dispatch(GetAllCertificationsBegin());

    try {
        const { data } = await WebClient.get('/certifications');
        dispatch(GetAllCertificationsSuccess(data));
    } catch (error) {
        dispatch(GetAllCertificationsError());
    }
};

const GetAllPermissionsBegin = () => ({
    type: UsersTypes.GET_PERMISSIONS_BEGIN,
});
const GetAllPermissionsSuccess = (payload) => ({
    type: UsersTypes.GET_PERMISSIONS_SUCCESS,
    payload,
});
const GetAllPermissionsError = () => ({
    type: UsersTypes.GET_PERMISSIONS_ERROR,
});

export const GetAllPermissions = () => async (dispatch) => {
    dispatch(GetAllPermissionsBegin());

    try {
        const { data } = await WebClient.get('/users/privileges');
        dispatch(GetAllPermissionsSuccess(data));
    } catch (error) {
        dispatch(GetAllPermissionsError());
    }
};
